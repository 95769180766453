<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<b-card no-body>
				<b-card-header>
					<b-row>
						<b-col cols="6">
							<h4>{{ rendTitle }}</h4>
						</b-col>
						<b-col cols="6" class="pull-right">
							<b-button
								:style="`${!$screen.md ? 'width:100%' : ''}`"
								@click="backToList"
								variant="success"
								size="md"
								class="btn btn-primary btn-md float-right"
							>
								{{ this.FormMSG(1, 'Back to list') }}&nbsp;<i class="icon-action-undo"></i>
							</b-button>
						</b-col>
					</b-row>
				</b-card-header>
				<b-card-body>
					<b-form @submit.prevent="onSubmit">
						<b-row>
							<b-col cols="12">
								<b-form-group :label="FormMSG(3, 'Name')" label-for="Name" :label-cols="3" :horizontal="true">
									<b-form-input v-model="team.name" type="text" :placeholder="FormMSG(4, 'Team name...')"></b-form-input>
								</b-form-group>
								<b-form-group :label="FormMSG(5, 'Description')" label-for="Description" :label-cols="3" :horizontal="true">
									<b-form-textarea v-model="team.description" type="text" :placeholder="FormMSG(6, 'Team description...')" rows="3" />
								</b-form-group>
								<b-form-group :label="FormMSG(7, 'Color')" label-for="Color" :label-cols="3" :horizontal="true">
									<v-select
										v-model="selecedColor"
										placeholder="Choose a color..."
										:options="colors"
										@option:selected="onSelectColor"
										@input="onResetColor"
									>
										<template #selected-option="option">
											<div style="line-height: 26px">
												<b-img :blank-color="option.color" width="26" height="26" rounded="circle" blank />
												<span class="ml-2">{{ option.label }}</span>
											</div>
										</template>
										<template #option="option">
											<div style="line-height: 26px">
												<b-img :blank-color="option.color" width="26" height="26" rounded="circle" blank />
												<span class="ml-2">{{ option.label }}</span>
											</div>
										</template>
									</v-select>
								</b-form-group>
								<b-form-group :label="FormMSG(8, 'Members')" label-for="Members" :label-cols="3" :horizontal="true">
									<div id="task-user-selector">
										<v-select
											multiple
											v-model="team.members"
											:placeholder="FormMSG(12, 'Choose users...')"
											:options="users"
											label="fullName"
											@option:selected="addMember"
											@option:deselected="removeMember"
											:disabled="userDisabled"
											:loading="userLoading"
										>
											<template #selected-option="option">
												<div v-if="option.picture && option.picture !== ''" class="image" v-lazy-container="{ selector: 'img' }">
													<img
														:data-src="showPicture(option.picture)"
														class="assigned-user-image"
														alt="photo"
														data-error="img/brand/avatar.jpg"
														data-loading="img/brand/tenor.gif"
														style="width: 26px !important; height: 26px !important"
													/>
													<span class="ml-1 mr-1">{{ option.fullName }}</span>
												</div>
												<div v-else class="pt-1 pb-1">
													<div
														:style="{
															backgroundColor: randomizeColor
														}"
														class="not-img"
													>
														<div>
															{{ initial(option.fullName) }}
														</div>
													</div>
													<span class="ml-1 mr-1">{{ option.fullName }}</span>
												</div>
											</template>
											<template #option="option">
												<div v-if="option.picture && option.picture !== ''" class="image" v-lazy-container="{ selector: 'img' }">
													<img
														:data-src="showPicture(option.picture)"
														class="assigned-user-image"
														alt="photo"
														data-error="img/brand/avatar.jpg"
														data-loading="img/brand/tenor.gif"
														style="width: 26px !important; height: 26px !important"
													/>
													<span class="ml-1 mr-1">{{ option.fullName }}</span>
												</div>
												<div v-else>
													<div
														:style="{
															backgroundColor: randomizeColor
														}"
														class="not-img"
													>
														<div>
															{{ initial(option.fullName) }}
														</div>
													</div>
													<span class="ml-1 mr-1">{{ option.fullName }}</span>
												</div>
											</template>
										</v-select>
									</div>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col cols="12">
								<b-form-group class="pull-right">
									<b-button type="submit" variant="primary" size="md">
										<span>{{ FormMSG(11, 'Save') }}</span>
									</b-button>
								</b-form-group>
							</b-col>
						</b-row>
					</b-form>
				</b-card-body>
			</b-card>
		</transition>
	</errorContainer>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { getTeam, updatedTeam, addTeamMember, delTeamMember } from '@/cruds/team.crud';
import { getUsersOfProject } from '@/cruds/users.crud';

export default {
	name: 'TeamForm',
	mixins: [languageMessages, globalMixin],
	computed: {
		rendTitle() {
			return this.FormMSG(2, 'Update Team');
		}
	},
	data() {
		return {
			erreur: {},
			warning: '',
			team: {},
			users: [],
			rawUsers: [],
			colors: [],
			selecedColor: null,
			userDisabled: false,
			userLoading: false
		};
	},
	mounted() {
		const { id } = this.$route.params;
		this.$nextTick(async () => {
			// get team
			this.team = await getTeam(id);
			// get all colors
			this.getColor();
			// get all users
			await this.getUsers();
			// set color
			this.selectColor(this.team);
		});
	},
	methods: {
		initial(fullName) {
			if (fullName && fullName.length > 0) {
				return fullName.charAt(0).toUpperCase();
			}
			return '';
		},
		backToList() {
			this.$router.push('/teams');
		},
		getColor() {
			const colors = this.FormMenu(1315);
			colors.forEach((color) => {
				this.colors.push({
					id: color.value,
					label: color.text,
					color: '#' + this.base16ToHexa(color.value)
				});
			});
			this.colors.sort((a, b) => {
				if (a.label < b.label) {
					return -1;
				}
				if (a.label > b.label) {
					return 1;
				}

				return 0;
			});
		},
		async getUsers() {
			await getUsersOfProject().then((records) => {
				this.rawUsers = records;
				if (this.rawUsers.length > 0) {
					this.rawUsers.sort((a, b) => {
						if (a.fullName < b.fullName) {
							return -1;
						}
						if (a.fullName > b.fullName) {
							return 1;
						}

						return 0;
					});
				}
			});

			this.users = this.rawUsers;

			if (this.team.members.length > 0) {
				for (let assignee of this.team.members) {
					this.users = this.users.filter((user) => assignee.id !== user.id);
				}
				this.users.sort((a, b) => {
					if (a.fullName < b.fullName) {
						return -1;
					}
					if (a.fullName > b.fullName) {
						return 1;
					}

					return 0;
				});
			}
		},
		async onSubmit() {
			// update team
			await updatedTeam(this.team.id, this.team).then((res) => {
				if (res) {
					this.createToastForMobile(this.FormMSG(14, 'Success'), this.FormMSG(13, 'Team updated successfully'), '', 'success');
				}
			});
		},
		onSelectColor(payload) {
			this.team.color = payload.id;
		},
		onResetColor(payload) {
			this.team.color = 0;
		},
		selectColor(team) {
			const selectedColor = this.colors.filter((color) => color.id === team.color);
			this.selecedColor = selectedColor.length === 0 ? null : selectedColor[0];
		},
		showPicture(picture) {
			return process.env.VUE_APP_GQL + '/images/' + picture;
		},
		async addMember(payload) {
			this.userDisabled = true;
			this.userLoading = true;
			const newUser = payload[payload.length - 1];
			await addTeamMember(this.team.id, newUser.id).then((user) => {
				if (user) {
					this.users = this.users.filter((user) => user.id !== newUser.id);
					this.userDisabled = false;
					this.userLoading = false;
				}
			});
		},
		async removeMember(payload) {
			this.userDisabled = true;
			this.userLoading = true;
			await delTeamMember(this.team.id, payload.id).then((res) => {
				if (res) {
					this.users.push(payload);
					this.users.sort((a, b) => {
						if (a.fullName < b.fullName) {
							return -1;
						}
						if (a.fullName > b.fullName) {
							return 1;
						}

						return 0;
					});
					this.userDisabled = false;
					this.userLoading = false;
				}
			});
		}
	}
};
</script>
<style lang="scss">
#task-user-selector .v-select,
#task-user-selector .v-select * {
	box-sizing: border-box;
	line-height: 26px;
	height: auto;
}
.not-img {
	display: inline-block;
	border-radius: 26px;
	width: 26px;
	height: 26px;
	color: white;
	position: relative;
	> div {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		justify-content: center;
		align-items: center;
		display: flex;
	}
}
</style>
