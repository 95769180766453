var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "transition",
        { attrs: { name: "slide" } },
        [
          _c(
            "b-card",
            { attrs: { "no-body": "" } },
            [
              _c(
                "b-card-header",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { cols: "6" } }, [
                        _c("h4", [_vm._v(_vm._s(_vm.rendTitle))]),
                      ]),
                      _c(
                        "b-col",
                        { staticClass: "pull-right", attrs: { cols: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn btn-primary btn-md float-right",
                              style: `${!_vm.$screen.md ? "width:100%" : ""}`,
                              attrs: { variant: "success", size: "md" },
                              on: { click: _vm.backToList },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(this.FormMSG(1, "Back to list")) +
                                  " "
                              ),
                              _c("i", { staticClass: "icon-action-undo" }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-card-body",
                [
                  _c(
                    "b-form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.onSubmit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(3, "Name"),
                                    "label-for": "Name",
                                    "label-cols": 3,
                                    horizontal: true,
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.FormMSG(
                                        4,
                                        "Team name..."
                                      ),
                                    },
                                    model: {
                                      value: _vm.team.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.team, "name", $$v)
                                      },
                                      expression: "team.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(5, "Description"),
                                    "label-for": "Description",
                                    "label-cols": 3,
                                    horizontal: true,
                                  },
                                },
                                [
                                  _c("b-form-textarea", {
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.FormMSG(
                                        6,
                                        "Team description..."
                                      ),
                                      rows: "3",
                                    },
                                    model: {
                                      value: _vm.team.description,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.team, "description", $$v)
                                      },
                                      expression: "team.description",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(7, "Color"),
                                    "label-for": "Color",
                                    "label-cols": 3,
                                    horizontal: true,
                                  },
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      placeholder: "Choose a color...",
                                      options: _vm.colors,
                                    },
                                    on: {
                                      "option:selected": _vm.onSelectColor,
                                      input: _vm.onResetColor,
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "selected-option",
                                        fn: function (option) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "line-height": "26px",
                                                },
                                              },
                                              [
                                                _c("b-img", {
                                                  attrs: {
                                                    "blank-color": option.color,
                                                    width: "26",
                                                    height: "26",
                                                    rounded: "circle",
                                                    blank: "",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "ml-2" },
                                                  [_vm._v(_vm._s(option.label))]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "option",
                                        fn: function (option) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "line-height": "26px",
                                                },
                                              },
                                              [
                                                _c("b-img", {
                                                  attrs: {
                                                    "blank-color": option.color,
                                                    width: "26",
                                                    height: "26",
                                                    rounded: "circle",
                                                    blank: "",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "ml-2" },
                                                  [_vm._v(_vm._s(option.label))]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.selecedColor,
                                      callback: function ($$v) {
                                        _vm.selecedColor = $$v
                                      },
                                      expression: "selecedColor",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(8, "Members"),
                                    "label-for": "Members",
                                    "label-cols": 3,
                                    horizontal: true,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { attrs: { id: "task-user-selector" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          multiple: "",
                                          placeholder: _vm.FormMSG(
                                            12,
                                            "Choose users..."
                                          ),
                                          options: _vm.users,
                                          label: "fullName",
                                          disabled: _vm.userDisabled,
                                          loading: _vm.userLoading,
                                        },
                                        on: {
                                          "option:selected": _vm.addMember,
                                          "option:deselected": _vm.removeMember,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "selected-option",
                                            fn: function (option) {
                                              return [
                                                option.picture &&
                                                option.picture !== ""
                                                  ? _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "lazy-container",
                                                            rawName:
                                                              "v-lazy-container",
                                                            value: {
                                                              selector: "img",
                                                            },
                                                            expression:
                                                              "{ selector: 'img' }",
                                                          },
                                                        ],
                                                        staticClass: "image",
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticClass:
                                                            "assigned-user-image",
                                                          staticStyle: {
                                                            width:
                                                              "26px !important",
                                                            height:
                                                              "26px !important",
                                                          },
                                                          attrs: {
                                                            "data-src":
                                                              _vm.showPicture(
                                                                option.picture
                                                              ),
                                                            alt: "photo",
                                                            "data-error":
                                                              "img/brand/avatar.jpg",
                                                            "data-loading":
                                                              "img/brand/tenor.gif",
                                                          },
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "ml-1 mr-1",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                option.fullName
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "pt-1 pb-1",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "not-img",
                                                            style: {
                                                              backgroundColor:
                                                                _vm.randomizeColor,
                                                            },
                                                          },
                                                          [
                                                            _c("div", [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.initial(
                                                                      option.fullName
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "ml-1 mr-1",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                option.fullName
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "option",
                                            fn: function (option) {
                                              return [
                                                option.picture &&
                                                option.picture !== ""
                                                  ? _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "lazy-container",
                                                            rawName:
                                                              "v-lazy-container",
                                                            value: {
                                                              selector: "img",
                                                            },
                                                            expression:
                                                              "{ selector: 'img' }",
                                                          },
                                                        ],
                                                        staticClass: "image",
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticClass:
                                                            "assigned-user-image",
                                                          staticStyle: {
                                                            width:
                                                              "26px !important",
                                                            height:
                                                              "26px !important",
                                                          },
                                                          attrs: {
                                                            "data-src":
                                                              _vm.showPicture(
                                                                option.picture
                                                              ),
                                                            alt: "photo",
                                                            "data-error":
                                                              "img/brand/avatar.jpg",
                                                            "data-loading":
                                                              "img/brand/tenor.gif",
                                                          },
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "ml-1 mr-1",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                option.fullName
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _c("div", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "not-img",
                                                          style: {
                                                            backgroundColor:
                                                              _vm.randomizeColor,
                                                          },
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.initial(
                                                                    option.fullName
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "ml-1 mr-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              option.fullName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                              ]
                                            },
                                          },
                                        ]),
                                        model: {
                                          value: _vm.team.members,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.team, "members", $$v)
                                          },
                                          expression: "team.members",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "b-form-group",
                                { staticClass: "pull-right" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        type: "submit",
                                        variant: "primary",
                                        size: "md",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.FormMSG(11, "Save"))),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }